var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"auth-wrapper auth-v2"},[_c('b-row',{staticClass:"auth-inner m-0"},[_c('b-link',{staticClass:"brand-logo",attrs:{"to":"/"}},[_c('img',{staticClass:"img",attrs:{"src":require("@/assets/images/logo/logo.png"),"alt":""}}),_c('h2',{staticClass:"brand-text text-primary ml-1"},[_vm._v("FruitScreen")])]),_c('b-col',{staticClass:"d-none d-lg-flex align-items-center p-5",attrs:{"lg":"8"}},[_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-5"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Forgot password"}})],1)]),_c('b-col',{staticClass:"d-flex align-items-center auth-bg px-2 p-lg-5",attrs:{"lg":"4"}},[_c('b-col',{staticClass:"px-xl-2 mx-auto",attrs:{"sm":"8","md":"6","lg":"12"}},[_c('b-card-title',{staticClass:"font-weight-bold mb-1",attrs:{"title-tag":"h2"}},[_vm._v(" "+_vm._s(_vm.$t('Forgot Password'))+"? 🔒 ")]),_c('b-card-text',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(_vm.$t("Enter your email and we'll send you instructions to reset your password"))+" ")]),_c('validation-observer',{ref:"resetForm"},[_c('b-form',{staticClass:"auth-forgot-password-form mt-2",on:{"submit":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_c('b-form-group',{attrs:{"label":_vm.$t('Mobile'),"label-for":"forgot-password-mobile"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Mobile'),"rules":"required|mobile"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"forgot-password-mobile","state":errors.length > 0 ? false:null,"name":"forgot-password-mobile","placeholder":_vm.$t('Please Enter...')},model:{value:(_vm.form.tel),callback:function ($$v) {_vm.$set(_vm.form, "tel", $$v)},expression:"form.tel"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":_vm.$t('Code'),"label-for":"register-code"}},[_c('validation-provider',{attrs:{"name":_vm.$t('Code'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"register-code","type":"tel","state":errors.length > 0 ? false:null,"name":"register-code","placeholder":_vm.$t('Please Enter...')},model:{value:(_vm.form.code),callback:function ($$v) {_vm.$set(_vm.form, "code", $$v)},expression:"form.code"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[(_vm.codeSeconds==0)?_c('a',{staticClass:"text-primary",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.getCode(_vm.form.tel)}}},[_vm._v(_vm._s(_vm.$t('Get Verification Code')))]):_c('span',{staticClass:"text-grey"},[_vm._v(_vm._s(_vm.codeSeconds)+"s")])])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"password","label":_vm.$t('New Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('New Password'),"rules":"required","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"password","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"password","placeholder":_vm.$t('Please Enter...')},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"form.password"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label-for":"repassword","label":_vm.$t('Confirm Password')}},[_c('validation-provider',{attrs:{"name":_vm.$t('Confirm Password'),"rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",class:errors.length > 0 ? 'is-invalid':null},[_c('b-form-input',{staticClass:"form-control-merge",attrs:{"id":"repassword","type":_vm.passwordFieldType,"state":errors.length > 0 ? false:null,"name":"repassword","placeholder":_vm.$t('Please Enter...')},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('feather-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.passwordToggleIcon},on:{"click":_vm.togglePasswordVisibility}})],1)],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-button',{attrs:{"type":"submit","variant":"primary","block":""}},[_vm._v(" "+_vm._s(_vm.$t('Reset Password'))+" ")])],1)],1),_c('p',{staticClass:"text-center mt-2"},[_c('b-link',{attrs:{"to":{name:'login'}}},[_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon"}}),_vm._v(" "+_vm._s(_vm.$t('Back to login'))+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }