<template>
	<div class="auth-wrapper auth-v2">
		<b-row class="auth-inner m-0">
			<!-- Brand logo-->
			<b-link class="brand-logo" to="/">
				<img class="img" src="@/assets/images/logo/logo.png" alt="">
				<h2 class="brand-text text-primary ml-1">FruitScreen</h2>
			</b-link>
			<!-- /Brand logo-->

			<!-- Left Text-->
			<b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
				<div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
					<b-img fluid :src="imgUrl" alt="Forgot password" />
				</div>
			</b-col>
			<!-- /Left Text-->

			<!-- Forgot password-->
			<b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5" >
				<b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
					<b-card-title title-tag="h2" class="font-weight-bold mb-1">
						{{$t('Forgot Password')}}? 🔒
					</b-card-title>
					<b-card-text class="mb-2">
						{{$t("Enter your email and we'll send you instructions to reset your password")}}
					</b-card-text>

					<!-- form -->
					<validation-observer ref="resetForm">
						<b-form class="auth-forgot-password-form mt-2" @submit.prevent="validationForm">
							<!-- mobile -->
							<b-form-group :label="$t('Mobile')" label-for="forgot-password-mobile">
								<validation-provider
									#default="{ errors }"
									:name="$t('Mobile')"
									rules="required|mobile"
								>
									<b-form-input
										id="forgot-password-mobile"
										v-model="form.tel"
										:state="errors.length > 0 ? false:null"
										name="forgot-password-mobile"
										:placeholder="$t('Please Enter...')"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							
							<!-- verification code -->
							<b-form-group :label="$t('Code')" label-for="register-code">
								<validation-provider
									#default="{ errors }"
									:name="$t('Code')"
									rules="required"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid':null"
									>
										<b-form-input
											id="register-code"
											v-model="form.code"
											class="form-control-merge"
											type="tel"
											:state="errors.length > 0 ? false:null"
											name="register-code"
											:placeholder="$t('Please Enter...')"
										/>
										<b-input-group-append is-text>
											<a href="javascript:;"
												class="text-primary" v-if="codeSeconds==0"
												@click="getCode(form.tel)"
											>{{$t('Get Verification Code')}}</a>
											<span class="text-grey" v-else>{{codeSeconds}}s</span>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<!-- New password -->
							<b-form-group
								label-for="password"
								:label="$t('New Password')"
							>
								<validation-provider
									#default="{ errors }"
									:name="$t('New Password')"
									rules="required"
									vid="password"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid':null"
									>
										<b-form-input
											id="password"
											v-model.trim="form.password"
											class="form-control-merge"
											:type="passwordFieldType"
											:state="errors.length > 0 ? false:null"
											name="password"
											:placeholder="$t('Please Enter...')"
										/>
										<b-input-group-append is-text>
											<feather-icon
												:icon="passwordToggleIcon"
												class="cursor-pointer"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
							<!-- 确认密码 -->
							<b-form-group
								label-for="repassword"
								:label="$t('Confirm Password')"
							>
								<validation-provider
									#default="{ errors }"
									:name="$t('Confirm Password')"
									rules="required|confirmed:password"
								>
									<b-input-group
										class="input-group-merge"
										:class="errors.length > 0 ? 'is-invalid':null"
									>
										<b-form-input
											id="repassword"
											v-model="confirmPassword"
											class="form-control-merge"
											:type="passwordFieldType"
											:state="errors.length > 0 ? false:null"
											name="repassword"
											:placeholder="$t('Please Enter...')"
										/>
										<b-input-group-append is-text>
											<feather-icon
												:icon="passwordToggleIcon"
												class="cursor-pointer"
												@click="togglePasswordVisibility"
											/>
										</b-input-group-append>
									</b-input-group>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>

							<b-button
								type="submit"
								variant="primary"
								block
							>
								{{$t('Reset Password')}}
							</b-button>
						</b-form>
					</validation-observer>

					<p class="text-center mt-2">
						<b-link :to="{name:'login'}">
							<feather-icon icon="ChevronLeftIcon" /> {{$t('Back to login')}}
						</b-link>
					</p>
				</b-col>
			</b-col>
			<!-- /Forgot password-->
		</b-row>
	</div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
	BRow, BCol, BLink, BCardTitle, BCardText, BImg, BForm, BFormGroup, BFormInput, BButton,
	BInputGroup,
	BInputGroupAppend,
} from 'bootstrap-vue'
import { required, confirmed } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { sendCaptcha } from '@/mixins/captcha'
import {resetPassword,getCaptcha} from "@/libs/apis/userApi"
import md5 from "blueimp-md5";
let timer = null

export default {
	components: {
		VuexyLogo,
		BRow,
		BCol,
		BLink,
		BImg,
		BForm,
		BButton,
		BFormGroup,
		BFormInput,
		BInputGroup,
		BInputGroupAppend,
		BCardTitle,
		BCardText,
		ValidationProvider,
		ValidationObserver,
	},
	mixins: [togglePasswordVisibility, sendCaptcha],
	data() {
		return {
			confirmPassword: '',
			sideImg: require('@/assets/images/pages/forgot-password-v2.svg'),
			// validation
			required,
			confirmed,
			form: {
				tel: '',
				password: '',
				code: ''
			}
		}
	},
	created () {
		this.addMobileVerify()
	},
	computed: {
		passwordToggleIcon() {
			return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
		},
		imgUrl() {
			if (this.$store.state.appConfig.layout.skin === 'dark') {
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.sideImg = require('@/assets/images/pages/forgot-password-v2-dark.svg')
				return this.sideImg
			}
			return this.sideImg
		},
	},
	methods: {
		addMobileVerify () {
			extend('mobile', value => {
				return value.length==11 || this.$t('Mobile is not Valid')
			});
		},
		validationForm() {
			this.$refs.resetForm.validate().then(success => {
				if (success) {
					this.doReset()
				}
			})
		},

		doReset () {
			let params = Object.assign({},this.form,{password: md5(this.form.password)})
			resetPassword(params).then(res=>{
				this.$toast.success(this.$t('Reset Success'))
				this.$router.replace('/login')
			},err=>{
				this.$toast.error(err.message)
			})
		}
	},
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
